<script>
import formatDate from '@utils/format-date'
import { modalMethods } from '@state/helpers/modal'
import i18n from '@src/i18n'
export default {
  name: 'Filename',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      required: true,
      default: 'today',
    },
    filename: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'description',
    },
    onViewDocument: {
      type: Function,
      default: () => null,
    },
    onDeleteDocument: {
      type: Function,
      default: () => null,
    },
    url: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...modalMethods,
    renderDate(date) {
      return formatDate(date)
    },
    onPromptDelete(index) {
      const modalData = {
        confirmAction: this.resetModalData,
        declineAction: this.resetModalData,
        secondBtnAction: () => this.onDeleteDocument(index),
        secondBtnVisible: true,
        isModalVisible: true,
        confirmText: i18n.t('CANCEL'),
        secondBtnText: i18n.t('DELETE'),
        title: i18n.t('DELETE_POA_CONFIRM_TEXT'),
      }
      this.setModalData(modalData)
    },
  },
}
</script>

<template>
  <div :class="$style.documentCard">
    <v-row>
      <v-col cols="12" md="auto">
        <div :class="$style.info">
          <div :class="$style.fileName">{{ filename }}</div>
          <div :class="$style.date">{{ renderDate(date) }}</div>
        </div>
      </v-col>
      <v-col cols="12" md="auto">
        <div :class="$style.buttonWrap">
          <v-btn
            class="naked-btn tertiary smallest view darktxt"
            text
            small
            @click="
              onViewDocument({
                index: index,
                isDownload: false,
                name: filename,
              })
            "
            >{{ $t('VIEW') }}</v-btn
          >
          <v-btn
            class="naked-btn tertiary smallest bold view darktxt"
            text
            small
            @click="
              onViewDocument({
                index: index,
                isDownload: true,
                name: filename,
              })
            "
            >{{ $t('DOWNLOAD') }}</v-btn
          >
          <v-btn
            v-if="!readOnly"
            class="naked-btn remove"
            text
            small
            @click="onPromptDelete(index)"
            >{{ $t('REMOVE') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.documentCard {
  z-index: 1;
  padding: 25px 65px 18px 35px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.07);
}

.buttonWrap {
  margin-left: 45px;
  button {
    margin: 0 3px;
  }
}

.info {
  padding: 0 0 0 50px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill-rule='nonzero' fill='none'%3e%3cpath d='M3 29v2c0 .6.4 1 1 1h24c.6 0 1-.4 1-1v-2H3zM22 1.6V7h5.4z' fill='%23222F44'/%3e%3cpath d='M10.9 18.5h-.4v1.3h.3c.2 0 .4-.1.6-.2.2-.1.2-.3.2-.5 0-.4-.2-.6-.7-.6zM16 18.5h-.4v3.2h.3c.5 0 .8-.1 1-.4.2-.3.3-.7.3-1.2s-.1-.9-.3-1.2c-.2-.3-.4-.4-.9-.4z' fill='%231D1D1D'/%3e%3cpath d='M31 13h-2V9h-8c-.6 0-1-.4-1-1V0H4c-.6 0-1 .4-1 1v12H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h30c.6 0 1-.4 1-1V14c0-.6-.4-1-1-1zm-18.4 7.6c-.4.3-.9.5-1.6.5h-.4V23H9v-5.7h2c.7 0 1.3.2 1.6.5.3.3.5.8.5 1.3 0 .6-.1 1.1-.5 1.5zm5.5 1.6c-.5.5-1.3.8-2.2.8h-1.8v-5.7h2c.9 0 1.6.2 2.1.7.5.5.7 1.1.7 2 0 1-.3 1.7-.8 2.2zm5.2-3.7h-1.9v1.1h1.7v1.2h-1.7V23h-1.5v-5.7h3.4v1.2z' fill='%23222F44'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left 2px;
  background-size: 32px 32px;
}

.fileName {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: $dark;
  word-break: break-word;
}

.date {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: $grey;
}

.icon {
  margin-right: 15px;
  font-size: 32px !important;
  color: $dark !important;
}
</style>
