<script>
export default {
  props: {
    onSelectDocument: {
      type: Function,
      default: () => null,
    },
  },
  methods: {
    onButtonClick(event) {
      const input = event.target.querySelector('input')
      if (input) {
        input.click()
      }
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <v-btn class="naked-btn smallest primary" text @click="onButtonClick">
      {{ $t('UPLOAD_NEW_PDF') }}
      <v-file-input
        ref="upload"
        :class="$style.hide"
        :placeholder="$t('UPLOAD_NEW_PDF')"
        label="File input"
        accept=".pdf"
        multiple
        @change="onSelectDocument"
      ></v-file-input>
    </v-btn>
  </div>
</template>
<style lang="scss" module>
@import '@design';

.container {
  position: relative;
  display: inline;
  margin-left: 5px;
  :global(.v-text-field) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }
  .hide {
    display: none;
  }
}
</style>
