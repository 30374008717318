var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.documentCard},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('div',{class:_vm.$style.info},[_c('div',{class:_vm.$style.fileName},[_vm._v(_vm._s(_vm.filename))]),_c('div',{class:_vm.$style.date},[_vm._v(_vm._s(_vm.renderDate(_vm.date)))])])]),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('div',{class:_vm.$style.buttonWrap},[_c('v-btn',{staticClass:"naked-btn tertiary smallest view darktxt",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onViewDocument({
              index: _vm.index,
              isDownload: false,
              name: _vm.filename,
            })}}},[_vm._v(_vm._s(_vm.$t('VIEW')))]),_c('v-btn',{staticClass:"naked-btn tertiary smallest bold view darktxt",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onViewDocument({
              index: _vm.index,
              isDownload: true,
              name: _vm.filename,
            })}}},[_vm._v(_vm._s(_vm.$t('DOWNLOAD')))]),(!_vm.readOnly)?_c('v-btn',{staticClass:"naked-btn remove",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.onPromptDelete(_vm.index)}}},[_vm._v(_vm._s(_vm.$t('REMOVE')))]):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }