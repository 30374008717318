<script>
import DocumentCard from '@components/document-card/document-card'
import DocumentUpload from '@components/document-upload/document-upload'
import {
  enterpriseComputed,
  enterpriseMethods,
} from '@state/helpers/enterprise'
import {
  minLengthValidation,
  maxLengthValidation,
  isValidIbanNumber,
  swiftValidation,
} from '@utils/inputValidation'
import { lunadisPayOrigin } from '@constants/origins'
import { getSavedStateSession } from '@utils/storage'
import EnterpriseOriginToggle from '@components/enterprise-origin-toggle/enterprise-origin-toggle'
const Modal = () => import('@components/pdf-modal/pdf-modal')
export default {
  components: { DocumentCard, DocumentUpload, Modal, EnterpriseOriginToggle },
  data() {
    return {
      countries: ['Denmark', 'Sweden', 'Norway', 'Finland'],
      countryCode: ['+45', '+46', '+47', '+48', '+49', '+50'],
      documents: [],
      valid: false,
      items: [],
      ibanRules: [(value) => isValidIbanNumber(value)],
      swiftRules: [
        (value) => minLengthValidation(value, 7),
        (value) => maxLengthValidation(value, 11),
        (value) => swiftValidation(value),
      ],
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
      lunadisPayOrigin,
    }
  },
  computed: {
    ...enterpriseComputed,
  },
  watch: {
    enterpriseId(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.getPOADocuments()
      }
    },
  },
  mounted() {
    if (this.currentEnterpriseId) {
      return this.getPOADocuments()
    }
  },
  methods: {
    ...enterpriseMethods,
    resetUploadInput() {
      this.$refs.documentUpload.$refs.upload.reset()
    },
    onSelectDocument(files) {
      this.uploadPOADocument({
        file: files[0],
        resolveCallback: this.resetUploadInput,
      })
    },
    onViewDocument(data) {
      this.getPOADocument(data)
    },
    onDeleteDocument(index) {
      this.deletePOADocument(index)
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <h1 :class="$style.headingCompanySetting">{{ $t('COMPANY_SETTINGS') }}</h1>
    <v-form id="override" v-model="valid">
      <v-row>
        <v-col cols="12" md="6" :class="$style.left">
          <h4>{{ $t('COMPANY_INFORMATION') }}</h4>
          <div :class="$style.companyName">
            <div :class="$style.icon"></div>
            <div :class="$style.label">{{ $t('COMPANY_INFORMATION') }}</div>
            <div :class="$style.heading">{{ currentEnterprise.name }}</div>
          </div>
          <EnterpriseOriginToggle :origin="lunadisPayOrigin">
            <template slot="fallback">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="currentEnterprise.vat_number"
                    outlined
                    :label="
                      $t(
                        currentEnterprise.vat_number_type
                          ? `LABEL_${currentEnterprise.vat_number_type}`
                          : 'LABEL_VAT_NUMBER'
                      )
                    "
                    :placeholder="currentEnterprise.vat_number"
                    disabled
                    persistent-hint
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="currentEnterprise.vat_rate"
                    outlined
                    :label="$t('LABEL_PARTIAL_VAT_RATE')"
                    :placeholder="String(currentEnterprise.vat_rate)"
                    suffix="%"
                    disabled
                  />
                </v-col>
              </v-row>
            </template>
          </EnterpriseOriginToggle>
          <br />
          <h4>{{ $t('COMPANY_ADDRESS') }}</h4>
          <v-row>
            <v-col cols="12" sm="8">
              <v-select
                :label="$t('LABEL_COUNTRY')"
                :placeholder="currentEnterprise.country"
                :items="countries"
                :value="currentEnterprise.country"
                item-text="state"
                outlined
                disabled
                append-icon="arrow_drop_down"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                :label="$t('LABEL_CURRENCY')"
                :placeholder="currentEnterprise.currency"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="currentEnterprise.city"
                outlined
                :label="$t('LABEL_CITY')"
                :placeholder="currentEnterprise.city"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="currentEnterprise.postal_code"
                disabled
                outlined
                :label="$t('LABEL_POSTCODE')"
                :placeholder="currentEnterprise.postal_code"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="currentEnterprise.address"
                outlined
                :label="$t('LABEL_ADDRES_LINE') + ' 1'"
                :placeholder="currentEnterprise.address"
                disabled
              />
            </v-col>
            <v-col v-if="currentEnterprise.second_address" cols="12" md="12">
              <v-text-field
                v-model="currentEnterprise.second_address"
                outlined
                :label="$t('LABEL_ADDRES_LINE') + ' 2'"
                :placeholder="currentEnterprise.second_address"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="items"
                value="Test the select"
                item-text="state"
                :label="$t('LABEL_COUNTRY_CODE')"
                :placeholder="currentEnterprise.phone_country_code"
                append-icon="arrow_drop_down"
                outlined
                disabled
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="currentEnterprise.phone_number"
                outlined
                :label="$t('LABEL_COMPANY_PHONE')"
                :placeholder="currentEnterprise.phone_number"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="currentEnterprise.company_email"
                outlined
                disabled
                :label="$t('LABEL_COMPANY_EMAIL')"
                :placeholder="currentEnterprise.company_email"
              />
            </v-col>
          </v-row>
          <br />
          <h4>{{ $t('COMPANY_CONTACT_PERSON') }}</h4>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="currentEnterprise.contact_person_firstname"
                outlined
                :label="$t('LABEL_FIRST_NAME')"
                :placeholder="currentEnterprise.contact_person_firstname"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="currentEnterprise.contact_person_lastname"
                outlined
                :label="$t('LABEL_LAST_NAME')"
                :placeholder="currentEnterprise.contact_person_lastname"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="currentEnterprise.contact_person_email"
                outlined
                :label="$t('LABEL_CONTACT_EMAIL')"
                :placeholder="currentEnterprise.contact_person_email"
                disabled
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="items"
                item-text="state"
                :label="$t('LABEL_COUNTRY_CODE')"
                :placeholder="
                  currentEnterprise.contact_person_phone_country_code
                "
                append-icon="arrow_drop_down"
                disabled
                outlined
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="currentEnterprise.contact_person_phone_number"
                outlined
                :label="$t('LABEL_PHONE_NUMBER')"
                :placeholder="currentEnterprise.contact_person_phone_number"
                disabled
              />
            </v-col>
          </v-row>
        </v-col>
        <EnterpriseOriginToggle :origin="lunadisPayOrigin">
          <template slot="fallback">
            <v-col cols="12" md="6" :class="$style.right">
              <h4>{{ $t('BANK_INFORMATION') }}</h4>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="currentEnterprise.iban_number"
                    outlined
                    label="IBAN"
                    :placeholder="currentEnterprise.iban_number"
                    :rules="ibanRules"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="currentEnterprise.bic"
                    outlined
                    label="BIC / Swift"
                    :placeholder="currentEnterprise.bic"
                    :rules="swiftRules"
                    disabled
                  />
                </v-col>
              </v-row>
              <br />
              <h4>
                {{ $t('POWER_ATTORNEY_DOCUMENTS') }}
                <DocumentUpload
                  v-if="!isReadOnlyUser"
                  ref="documentUpload"
                  :on-select-document="onSelectDocument"
                />
              </h4>
              <!-- TODO format date -->
              <div v-if="getPoaDocumentsError">{{ $t('GET_POA_ERROR') }}</div>
              <div v-else-if="!getPoaDocumentsError && !poaDocuments.length">{{
                $t('GET_POA_NO_DATA')
              }}</div>

              <DocumentCard
                v-for="(document, index) in poaDocuments"
                :key="document.id"
                :index="index"
                :filename="document.file_name"
                :date="document.date_created"
                icon="description"
                :on-view-document="onViewDocument"
                :on-delete-document="onDeleteDocument"
                :url="poaDocument.url"
                :read-only="isReadOnlyUser"
              />
            </v-col>
          </template>
        </EnterpriseOriginToggle>
      </v-row>
      <v-row class="justify-center display-none">
        <!-- Save is not apart of MVP -->
        <!-- <v-btn class="btn primary-btn center" type="submit">{{
          $t('SAVE_CHANGES')
        }}</v-btn>-->
      </v-row>
    </v-form>
    <Modal
      v-if="poaDocument.url"
      :url="poaDocument.url"
      :name="poaDocument.name"
      :on-close-modal="resetPOADocument"
    ></Modal>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.headingCompanySetting {
  display: inline-block;
  margin: 40px 0 20px;
  color: $dark;
}
h4 {
  margin-bottom: 20px;
  color: $dark;
}

.left {
  padding: 12px 25px 12px 12px;
}
.right {
  padding: 12px 12px 12px 25px;
}

.container {
  width: 100%;
  padding: 0 40px 80px;
}

.companyName {
  padding: 30px 24px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 7px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  color: $grey;
}

.label {
  font-size: 12px;
  color: $grey;
}

.icon {
  float: left;
  width: 24px;
  height: 24px;
  margin: 10px 20px 0 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%238C949E' fill-rule='nonzero' d='M23.287 3.042l-10-3c-.302758-.0907076-.6305768-.0329504-.8841041.1557668C12.1493687.386484 11.9999781.6839458 12 1v5h2V2.344l8 2.4V22h-6V9c0-.5522848-.4477153-1-1-1H5c-.5522848 0-1 .4477152-1 1v13H2V12H1c-.5522847 0-1 .4477153-1 1v10c0 .5522847.4477153 1 1 1h22c.5522847 0 1-.4477153 1-1V4c0-.4417682-.2898167-.8312125-.713-.958zM12 21H8v-2h4v2zm0-4H8v-2h4v2zm0-4H8v-2h4v2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px 24px;
}
</style>
